import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserEmailFormComponent } from './user-email-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [
    UserEmailFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PayeeFormFieldsModule,
    PayeeInfoModule,
    MatCheckboxModule,
    MatFormFieldModule,
  ]
})
export class UserEmailFormModule { }
