import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ClientsService } from './clients/clients.service';
import { filter, first, map } from 'rxjs/operators';
import { Client } from 'src/types';

@Injectable({
  providedIn: 'root'
})
export class NoClientGuardService implements CanActivate {

  constructor(
    private clientsService: ClientsService,
    private router: Router,
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.clientsService.clientsSubject
      .pipe(
        filter(clients => !!clients),
        map(async (clients: unknown[]) => {
          if (clients.length > 0) {
            return await this.router.navigateByUrl('user');
          }
          return true;
        }),
        first(),
      )
      .toPromise();
  }
}
