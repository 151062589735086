import { FormArray, ValidatorFn } from "@angular/forms";

export function totalAmountValidator(attribute: string): ValidatorFn {
  return (arr: FormArray) => {
    const totalAmount = arr.controls.reduce((sum, control) => {
      return parseInt(control.value[attribute]) + sum;
    }, 0);
    if (totalAmount > 30_000_000) {
      return {
        max: {
          max: 30_000_000,
          actual: totalAmount,
        },
      };
    }
    return null;
  };
}