<h2>Az Ön email címe</h2>
<form [formGroup]="form" class="top-margin-15">
  <payee-form-field width="widest" height="tall">
    <input payee-input placeholder="Email cím" formControlName="email" />
    <ng-container payee-error *ngIf="userEmail.invalid && userEmail.dirty">
      <span *ngIf="userEmail.hasError('required')"
        >Mező kitöltése kötelező</span
      >
      <span *ngIf="userEmail.hasError('email')">Helytelen formátum</span>
      <span *ngIf="userEmail.hasError('unknown')">{{
        userEmail.errors.unknown
      }}</span>
    </ng-container>
  </payee-form-field>

  <div class="flex column top-margin-15">
    <mat-checkbox class="tos-checkbox" formControlName="terms_of_service">
      <span>Elolvastam és elfogadom az</span>
      <a
        target="_blank"
        href="https://www.payee.tech/legal/terms-and-conditions"
      >
        ÁSZF-et
      </a>
      <span>és az</span>
      <a target="_blank" href="https://www.payee.tech/legal/privacy-policy">
        Adatkezelési tájékoztatót.
      </a>
    </mat-checkbox>
    <mat-error *ngIf="terms_of_service.invalid && terms_of_service.dirty">
      Kötelező elfogadni
    </mat-error>
  </div>
</form>

<payee-info class="top-margin-15">
  Erre küldjük például a számlát, legfontosabb státuszokat az ügyről.
</payee-info>
