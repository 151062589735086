import { ValidatorFn } from "@angular/forms";
import moment, { isMoment, Moment } from "moment";

export function maxDateValidator(maxDate: Moment): ValidatorFn {
  return control => {
    let value = control.value as Moment | undefined;
    if (!value) {
      return null;
    }

    if (!isMoment(value)) {
      value = moment(value);
      if (!value.isValid()) {
        return {
          maxdate: {
            expected: maxDate.format('YYYY-MM-DD'),
            actual: 'invalid-date',
          },
        };
      }
    }

    if (value.isBefore(maxDate)) {
      return null;
    }
    return {
      maxdate: {
        expected: maxDate.format('YYYY-MM-DD'),
        actual: value.format('YYYY-MM-DD'),
      },
    };
  };
}