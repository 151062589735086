import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PayeeAccordionModule } from 'src/app/shared/payee-accordion/payee-accordion.module';
import { PayeeAutosaveModule } from 'src/app/shared/payee-autosave/payee-autosave.module';
import { PayeeBigSwitcherModule } from 'src/app/shared/payee-big-switcher/payee-big-switcher.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeHeaderModule } from 'src/app/shared/payee-header/payee-header.module';
import { PayeeHorizontalLineModule } from 'src/app/shared/payee-horizontal-line/payee-horizontal-line.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { PayeeProgressBarModule } from 'src/app/shared/payee-progress-bar/payee-progress-bar.module';
import { PayeeRadioModule } from 'src/app/shared/payee-radio/payee-radio.module';
import { PayeeSwitcherModule } from 'src/app/shared/payee-switcher/payee-switcher.module';
import { ButtonComponent } from './aa-new-form/components/buttons/button/button.component';
import { FormHeaderComponent } from './aa-new-form/components/form-header/form-header.component';
import {
  CaseStartSuccessDialogComponent
} from './aa-new-form/dialogs/case-start-success-dialog/case-start-success-dialog.component';
import {
  FormDataStepExitDialogComponent
} from './aa-new-form/dialogs/form-data-step-exit-dialog/form-data-step-exit-dialog.component';
import {
  FormExitWarningDialogComponent
} from "./aa-new-form/dialogs/form-exit-warning-dialog/form-exit-warning-dialog.component";
import {
  MoneyTransferDialogComponent
} from './aa-new-form/dialogs/money-transfer-dialog/money-transfer-dialog.component';
import {
  FormSelectorHeaderComponent
} from './aa-new-form/form-selector/form-selector-header/form-selector-header.component';
import { FormSelectorComponent } from './aa-new-form/form-selector/form-selector.component';
import { TypeSelectorComponent } from './aa-new-form/form-selector/type-selector/type-selector.component';
import {
  FormAddressComponent
} from "./aa-new-form/form-steps/init-step/components/form-address/form-address.component";
import {
  FormDataStepComponent
} from './aa-new-form/form-steps/init-step/components/form-data-step/form-data-step.component';
import { FormHouseComponent } from "./aa-new-form/form-steps/init-step/components/form-house/form-house.component";
import {
  FormOtherDataComponent
} from './aa-new-form/form-steps/init-step/components/form-other-data/form-other-data.component';
import {
  BankAccountDataBoxComponent
} from './aa-new-form/form-steps/init-step/data-entry/bank-account/bank-account-data-box/bank-account-data-box.component';
import {
  ClaimDataBoxComponent
} from './aa-new-form/form-steps/init-step/data-entry/claim/claim-data-box/claim-data-box.component';
import {
  ClaimFileUploadComponent
} from './aa-new-form/form-steps/init-step/data-entry/claim/claim-data-box/claim-evidence-upload/claim-evidence-upload.component';
import {
  ClientDataBoxComponent
} from './aa-new-form/form-steps/init-step/data-entry/client/client-data-box/client-data-box.component';
import {
  CommunicationDataBoxComponent
} from './aa-new-form/form-steps/init-step/data-entry/communication/communication-data-box/communication-data-box.component';
import { DataEntryComponent } from './aa-new-form/form-steps/init-step/data-entry/data-entry.component';
import {
  DebtorDataBoxComponent
} from './aa-new-form/form-steps/init-step/data-entry/debtor/debtor-data-box/debtor-data-box.component';
import {
  InvoiceDataBoxComponent
} from './aa-new-form/form-steps/init-step/data-entry/invoice/invoice-data-box/invoice-data-box.component';
import {
  BankAccountDialogComponent
} from './aa-new-form/form-steps/init-step/dialogs/bank-account-dialog/bank-account-dialog.component';
import { ClaimDialogComponent } from './aa-new-form/form-steps/init-step/dialogs/claim-dialog/claim-dialog.component';
import {
  ClientDialogComponent
} from './aa-new-form/form-steps/init-step/dialogs/client-dialog/client-dialog.component';
import {
  CommunicationDialogComponent
} from './aa-new-form/form-steps/init-step/dialogs/communication-dialog/communication-dialog.component';
import {
  DebtorDialogComponent
} from './aa-new-form/form-steps/init-step/dialogs/debtor-dialog/debtor-dialog.component';
import {
  InvoiceDialogComponent
} from './aa-new-form/form-steps/init-step/dialogs/invoice-dialog/invoice-dialog.component';
import { InitStepComponent } from './aa-new-form/form-steps/init-step/init-step.component';
import {
  PaymentDetailsComponent
} from "./aa-new-form/form-steps/payment-step/components/payment-details/payment-details.component";
import {
  PaymentMethodComponent
} from "./aa-new-form/form-steps/payment-step/components/payment-method/payment-method.component";
import { PaymentStepComponent } from './aa-new-form/form-steps/payment-step/payment-step.component';
import {
  OptionalClaimTitleComponent
} from './aa-new-form/form-steps/summary-step/components/optional-claim-title/optional-claim-title.component';
import {
  SummaryDescriptionBoxComponent
} from './aa-new-form/form-steps/summary-step/components/summary-description-box/summary-description-box.component';
import {
  SummaryOptionalClaimsBoxComponent
} from './aa-new-form/form-steps/summary-step/components/summary-optional-claims-box/summary-optional-claims-box.component';
import {
  SummaryStartCaseFeeBoxComponent
} from './aa-new-form/form-steps/summary-step/components/summary-start-case-fee-box/summary-start-case-fee-box.component';
import { SummaryStepComponent } from './aa-new-form/form-steps/summary-step/summary-step.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CashbookLoginFailedComponent
} from './components/cashbook/cashbook-login-failed/cashbook-login-failed.component';
import {
  CashbookLoginSuccessComponent
} from './components/cashbook/cashbook-login-success/cashbook-login-success.component';
import { ClaimsRerouterComponent } from './components/claims-rerouter/claims-rerouter.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { CustomInputFieldComponent } from './components/custom-input-field/custom-input-field.component';
import { AdminCasesComponent } from './components/dashboard/admin-cases/admin-cases.component';
import { CasesTableModule } from './components/dashboard/admin-cases/cases-table/cases-table.module';
import {
  ApiIntegrationsComponent
} from './components/dashboard/client-profile/api-integrations/api-integrations.component';
import {
  BankAccountEditComponent
} from './components/dashboard/client-profile/bank-account-edit/bank-account-edit.component';
import { UserOptionsComponent } from './components/dashboard/client-profile/client-options/client-options.component';
import { ClientProfileComponent } from './components/dashboard/client-profile/client-profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/dashboard/sidebar/sidebar.component';
import { UserProfileComponent } from './components/dashboard/user-profile/user-profile.component';
import { EhazSsoErrorComponent } from './components/ehaz/ehaz-sso-error/ehaz-sso-error.component';
import { EhazSsoComponent } from './components/ehaz/ehaz-sso/ehaz-sso.component';
import {
  GetPasswordLinkSuccessComponent
} from './components/forgot-password/get-password-link-success/get-password-link-success.component';
import { GetPasswordLinkComponent } from './components/forgot-password/get-password-link/get-password-link.component';
import {
  NewPasswordSuccessComponent
} from './components/forgot-password/new-password-success/new-password-success.component';
import { NewPasswordComponent } from './components/forgot-password/new-password/new-password.component';
import { HeaderAppComponent } from './components/headers/header-app/header-app.component';
import { HeaderEmptyComponent } from './components/headers/header-empty/header-empty.component';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/login/register/register.component';
import { SignupFinalizationComponent } from './components/login/signup-finalization/signup-finalization.component';
import { VerifyEmailComponent } from './components/login/verify-email/verify-email.component';
import { VerifyTokenExpiredComponent } from './components/login/verify-token-expired/verify-token-expired.component';
import { PulsatingRingComponent } from './components/pulsating-ring/pulsating-ring.component';
import {
  SzamlazzhuSsoErrorComponent
} from "./components/szamlazzhu/szamlazzhu-sso-error/szamlazzhu-sso-error.component";
import {
  SzamlazzhuSsoResponseComponent
} from "./components/szamlazzhu/szamlazzhu-sso-response/szamlazzhu-sso-response.component";
import { SzamlazzhuSsoComponent } from './components/szamlazzhu/szamlazzhu-sso/szamlazzhu-sso.component';
import { DeleteKeyDialogComponent } from './dialogs/api-keys/delete-key-dialog/delete-key-dialog.component';
import { DiscardCaseDialogComponent } from './dialogs/case/discard-case-dialog/discard-case-dialog.component';
import {
  AddMissingTaxidDialogComponent
} from './dialogs/client/add-missing-taxid-dialog/add-missing-taxid-dialog.component';
import { AcceptTosDialogComponent } from './dialogs/profile/accept-tos-dialog/accept-tos-dialog.component';
import {
  ChangePasswordDialogComponent
} from './dialogs/profile/change-password-dialog/change-password-dialog.component';
import { TabDirectiveDirective } from './directives/tab-directive/tab-directive.directive';
import { DropzoneDirective } from './dropzone.directive';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { RedirectService } from "./services/redirect/redirect.service";
import { GeneralSuccessDialogComponent } from './shared/general-success-dialog/general-success-dialog.component';
import { WorkflowDialogModule } from './shared/workflow-dialogs/workflow-dialog.module';
import { ErrorSnackbarComponent } from './snackbars/error-snackbar/error-snackbar.component';
import { FormErrorSnackbarComponent } from './snackbars/form-error-snackbar/form-error-snackbar.component';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { BankAccountFormComponent } from './components/bank-account/bank-account-form/bank-account-form.component';
import { HeaderModule } from './components/dashboard/header/header.module';
import { TwofaFormComponent } from './components/login/twofa-form/twofa-form.component';
import { CreateClientDialogComponent } from './dialogs/client/create-client-dialog/create-client-dialog.component';
import {
  ChangePasswordSuccessDialogModule
} from './dialogs/profile/change-password-success-dialog/change-password-success-dialog.module';
import { GeneralLoadingDialogModule } from './shared/general-loading-dialog/general-loading-dialog.module';
import { LoadingScreenModule } from './shared/loading-screen/loading-screen.module';
import {
  PayeeCompanySearchInputFieldModule
} from './shared/payee-company-search-input-field/payee-company-search-input-field.module';
import { PayeeCompletedSearchBoxModule } from './shared/payee-completed-search-box/payee-completed-search-box.module';
import { PayeeToggleModule } from './shared/payee-toggle/payee-toggle.module';

import { RouterModule } from '@angular/router';
import { FooterModule } from 'src/app/shared/footer/footer.module';
import { LanguageDataBoxComponent } from './aa-new-form/form-steps/init-step/data-entry/language/language-data-box/language-data-box.component';
import { LanguageDialogComponent } from './aa-new-form/form-steps/init-step/dialogs/language-dialog/language-dialog.component';
import { PaymentRedirectStepComponent } from './aa-new-form/form-steps/payment-redirect-step/payment-redirect-step.component';
import { AddNewClientFormModule } from './components/add-new-client/add-new-client-form/add-new-client-form.module';
import { AddNewClientOnboardingComponent } from './components/add-new-client/add-new-client-onboarding/add-new-client-onboarding.component';
import { AddNewClientSuccessComponent } from './components/add-new-client/add-new-client-success/add-new-client-success.component';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { LoginTemplateModule } from './shared/login-template/login-template.module';
import { FormTemplateModule } from './shared/screen-templates/form-template/form-template.module';
import { ChapterComponent } from './user-guide/chapter/chapter.component';
import { SidePanelComponent } from './user-guide/side-panel/side-panel.component';
import { TableComponent } from './user-guide/table/table.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import * as FS from '@fullstory/browser';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CaseFormModule } from './components/case-form/case-form.module';

registerLocaleData(localeHu, 'hu');

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const long = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
    // const narrow = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
    const short = ['Vas', 'Hét', 'Kedd', 'Szer', 'Csüt', 'Pén', 'Szom'];
    switch (style) {
      case 'long': return long;
      case 'narrow': return short;
      case 'short': return short;
      default: return long;
    }
  }


  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const long = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
    // const narrow = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
    const short = ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'];
    switch (style) {
      case 'long': return long;
      case 'narrow': return short;
      case 'short': return short;
      default: return long;
    }
  }
}

export const DateFormats: MatDateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD'],
  },
  display: {
    dateInput: 'YYYY.MM.DD.',
    monthYearLabel: 'YYYY.MM.DD.',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AcceptTosDialogComponent,
    AddMissingTaxidDialogComponent,
    AddNewClientOnboardingComponent,
    AddNewClientSuccessComponent,
    AdminCasesComponent,
    ApiIntegrationsComponent,
    AppComponent,
    BankAccountDataBoxComponent,
    BankAccountDialogComponent,
    BankAccountEditComponent,
    BankAccountFormComponent,
    ButtonComponent,
    CaseStartSuccessDialogComponent,
    CashbookLoginFailedComponent,
    CashbookLoginSuccessComponent,
    ChangePasswordDialogComponent,
    ChapterComponent,
    ClaimDataBoxComponent,
    ClaimDialogComponent,
    ClaimFileUploadComponent,
    ClaimsRerouterComponent,
    ClientDataBoxComponent,
    ClientDialogComponent,
    ClientProfileComponent,
    CommunicationDataBoxComponent,
    CommunicationDialogComponent,
    CreateClientDialogComponent,
    CustomCheckboxComponent,
    CustomCheckboxComponent,
    CustomInputFieldComponent,
    DashboardComponent,
    DataEntryComponent,
    DebtorDataBoxComponent,
    DebtorDialogComponent,
    DeleteKeyDialogComponent,
    DiscardCaseDialogComponent,
    DropzoneDirective,
    EhazSsoComponent,
    EhazSsoErrorComponent,
    ErrorSnackbarComponent,
    FormAddressComponent,
    FormDataStepComponent,
    FormDataStepExitDialogComponent,
    FormErrorSnackbarComponent,
    FormExitWarningDialogComponent,
    FormHeaderComponent,
    FormHouseComponent,
    FormOtherDataComponent,
    FormSelectorComponent,
    FormSelectorHeaderComponent,
    GeneralSuccessDialogComponent,
    GetPasswordLinkComponent,
    GetPasswordLinkSuccessComponent,
    HeaderAppComponent,
    HeaderEmptyComponent,
    InitStepComponent,
    InvoiceDataBoxComponent,
    InvoiceDialogComponent,
    LanguageDataBoxComponent,
    LanguageDialogComponent,
    LazyLoadDirective,
    LoginComponent,
    MoneyTransferDialogComponent,
    NewPasswordComponent,
    NewPasswordSuccessComponent,
    OptionalClaimTitleComponent,
    PaymentDetailsComponent,
    PaymentDetailsComponent,
    PaymentMethodComponent,
    PaymentRedirectStepComponent,
    PaymentStepComponent,
    PhonePipe,
    PulsatingRingComponent,
    RegisterComponent,
    SidebarComponent,
    SidePanelComponent,
    SignupFinalizationComponent,
    SummaryDescriptionBoxComponent,
    SummaryOptionalClaimsBoxComponent,
    SummaryStartCaseFeeBoxComponent,
    SummaryStepComponent,
    SzamlazzhuSsoComponent,
    SzamlazzhuSsoErrorComponent,
    SzamlazzhuSsoResponseComponent,
    TabDirectiveDirective,
    TableComponent,
    TwofaFormComponent,
    TypeSelectorComponent,
    UserGuideComponent,
    UserOptionsComponent,
    UserProfileComponent,
    VerifyEmailComponent,
    VerifyTokenExpiredComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        deps: [HttpClient]
      },
      extend: true,
    }),
    AddNewClientFormModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CasesTableModule,
    ChangePasswordSuccessDialogModule,
    FooterModule,
    FormsModule,
    FormTemplateModule,
    GeneralLoadingDialogModule,
    HeaderModule,
    HttpClientModule,
    LoadingScreenModule,
    LoginTemplateModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    PayeeAccordionModule,
    PayeeAutosaveModule,
    PayeeBigSwitcherModule,
    PayeeButtonsModule,
    PayeeCompanySearchInputFieldModule,
    PayeeCompletedSearchBoxModule,
    PayeeDropzoneModule,
    PayeeFormFieldsModule,
    PayeeHeaderModule,
    PayeeHorizontalLineModule,
    PayeeIconModule,
    PayeeInfoModule,
    PayeeInputModule,
    PayeePopupModule,
    PayeeProgressBarModule,
    PayeeRadioModule,
    PayeeSwitcherModule,
    PayeeToggleModule,
    ReactiveFormsModule,
    RouterModule,
    WorkflowDialogModule,
    CaseFormModule,
  ],
  providers: [
    AuthGuardService,
    RedirectService,
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
  schemas: [],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(
    protected authService: AuthService,
  ) {
    FS.init({
      orgId: 'o-1RZVV3-na1',
      devMode: !environment.production,
    });
  }
}
