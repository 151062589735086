import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { CountryItem, PartyTypeItem } from 'src/app/services/enum/enum.service';
import { FormService } from 'src/app/services/form/form.service';
import { CompanySearchAutocompleteItem, CompanySearchService } from 'src/app/shared/company-search-service/company-search.service';

@Component({
  selector: 'app-debtor-org-form',
  templateUrl: './debtor-org-form.component.html',
  styleUrls: ['./debtor-org-form.component.scss']
})
export class DebtorOrgFormComponent implements OnInit, OnDestroy {
  get form(): FormGroup { return this.formService.form.get('debtor') as FormGroup; }

  companySearchOptions: CompanySearchAutocompleteItem[] = [];
  @Output() validCompanySelected = new EventEmitter<void>();

  debtorPartyType = new BehaviorSubject<PartyTypeItem | null>(null);
  @Input() partyTypeOptions: PartyTypeItem[] = [];

  debtorCountry = new BehaviorSubject<CountryItem | null>(null);
  representativeCountry = new BehaviorSubject<CountryItem | null>(null);
  @Input() countryOptions: CountryItem[] = [];

  private readonly destroy = new Subject<void>();
  get autocompleteSearchLoading() { return this.companySearchService.autocompleteSearchLoading; }

  get debtor_type(): FormControl { return this.form.get('debtor_type') as FormControl; }
  get name(): FormControl { return this.form.get('name') as FormControl; }
  get tax_number(): FormControl { return this.form.get('tax_number') as FormControl; }
  get party_type_id(): FormControl { return this.form.get('party_type_id') as FormControl; }
  get country_iso(): FormControl { return this.form.get('address.country_iso') as FormControl; }
  get debtorPostcode(): FormControl { return this.form.get('address.postcode') as FormControl; }
  get debtorSettlement(): FormControl { return this.form.get('address.settlement') as FormControl; }
  get debtorStreet(): FormControl { return this.form.get('address.street') as FormControl; }
  get repName(): FormControl { return this.form.get('representative.name') as FormControl; }
  get rep_country_iso(): FormControl { return this.form.get('representative.address.country_iso') as FormControl; }
  get repPostcode(): FormControl { return this.form.get('representative.address.postcode') as FormControl; }
  get repSettlement(): FormControl { return this.form.get('representative.address.settlement') as FormControl; }
  get repStreet(): FormControl { return this.form.get('representative.address.street') as FormControl; }

  constructor(
    private formService: FormService,
    private companySearchService: CompanySearchService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.name.valueChanges
      .pipe(
        takeUntil(this.destroy),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe({
        next: async name => {
          try {
            this.companySearchOptions = await this.companySearchService.getCompanySearchAutocompleteItems(name);
          } catch (error) {
            console.error('Error while company autocomplete', error);
          }
        }
      });

    this.party_type_id.valueChanges
      .pipe(
        takeUntil(this.destroy),
        startWith(this.party_type_id.value),
        // Wait for next tick to avoid errors
        debounceTime(0),
      )
      .subscribe({
        next: (partyType: null | string | PartyTypeItem) => {
          if (!partyType || typeof partyType === 'string') {
            this.debtorPartyType.next(this.partyTypeOptions.find(pt => pt.id === partyType));
            return;
          }
          this.party_type_id.patchValue(partyType.id);
          this.debtorPartyType.next(partyType);
        }
      });

    this.debtorPartyType.pipe(
      takeUntil(this.destroy),
      debounceTime(0),
    )
      .subscribe({
        next: partyType => {
          if (this.debtor_type.value === 'ind' || partyType?.type === 'ind') {
            this.form.get('representative').disable({ onlySelf: true });
          } else {
            this.form.get('representative').enable({ onlySelf: true });
          }
          this.form.updateValueAndValidity();
        }
      });

    this.country_iso.valueChanges
      .pipe(takeUntil(this.destroy), startWith(this.country_iso.value))
      .subscribe({
        next: (country: null | string | CountryItem) => {
          if (!country || typeof country === 'string') {
            this.debtorCountry.next(this.countryOptions.find(c => c.iso === country));
            return;
          }
          this.country_iso.patchValue(country.iso);
          this.debtorCountry.next(country);
        },
      });

    this.rep_country_iso.valueChanges
      .pipe(takeUntil(this.destroy), startWith(this.rep_country_iso.value))
      .subscribe({
        next: (country: null | string | CountryItem) => {
          if (!country || typeof country === 'string') {
            this.representativeCountry.next(this.countryOptions.find(c => c.iso === country));
            return;
          }
          this.rep_country_iso.patchValue(country.iso);
          this.representativeCountry.next(country);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async companySelected(event: MatAutocompleteSelectedEvent): Promise<void> {
    try {
      const company = event.option.value as CompanySearchAutocompleteItem;
      this.form.patchValue({
        name: company.name,
        tax_number: company.tax_number,
        address: {
          // @todo replace id with iso in DNB service
          // country_iso: company.country,
          postcode: company.postcode,
          settlement: company.settlement,
          street: company.street,
        },
      });

      this.form.disable();
      const result = await this.companySearchService.detailedSearch(company.tax_number);

      if (!result) {
        console.warn('Company detailed search was null!');
        return;
      }

      this.form.patchValue({
        party_type_id: result.company.basicData.partyType?.internal_id,
        // @todo only use email
        email: result.company.contactData.value,
        representative: {
          name: result.company.representativeData.representative.name,
          address: {
            // @todo replace id with iso in DNB service
            // country_iso: result.company.representativeData.address.country,
            postcode: result.company.representativeData.address?.postcode,
            settlement: result.company.representativeData.address?.settlement,
            street: result.company.representativeData.address?.street,
          },
        },
      });
    } catch (error) {
      console.error('Error while company detailed search', error);
    } finally {
      this.form.enable();
      this.form.updateValueAndValidity();

      if (this.form.valid) {
        this.validCompanySelected.next();
      }
    }
  }

  readonly partyTypeDisplayWith = (partyType?: PartyTypeItem | string): string => {
    if (!partyType) {
      return '';
    }
    if (typeof partyType === 'string') {
      return this.partyTypeOptions.find(pt => pt.id === partyType)?.label ?? '';
    }
    return partyType?.label ?? '';
  };

  countryDisplayWith(country?: CountryItem): string {
    return country?.name ?? '';
  }

  companySearchDisplayWith(company?: CompanySearchAutocompleteItem): string {
    return company?.name ?? '';
  }
}
