import { AbstractControl, FormBuilder } from "@angular/forms";
import { FormService } from "./form.service";
import { getClaimForm } from "./forms/claim";
import { getClaimsForm } from "./forms/claims";
import { getClientForm } from "./forms/client";
import { getDebtorForm } from "./forms/debtor";
import { getBankAccountForm } from "./forms/bank-account";
import { getSummaryForm } from "./forms/summary";
import { BankAccount, Campaign, Claim, Client, Debtor, Summary, SzamlazzhuInvoice, UserEmail } from "./form.resources";
import { getUserEmailForm } from "./forms/user-email";
import { getSzamlazzhuInvoicesForm } from "./forms/szamlazzhu-invoices";
import { getSzamlazzhuInvoiceForm } from "./forms/szamlazzhu-invoice";

export type Form = {
  debtor: Debtor;
  claims: Claim[];
  claim: Claim;
  client: Client;
  user: UserEmail;
  'bank-account': BankAccount;
  summary: Summary;

  // Szamlazz.hu
  'szamlazzhu-invoice': SzamlazzhuInvoice,
  'szamlazzhu-invoices': SzamlazzhuInvoice[],

  // Helper
  'campaign': Campaign,
};

export type FormName = keyof Form;

export class FormFactory {
  constructor(
    protected formService: FormService,
    protected fb: FormBuilder,
    protected data?: Form[FormName],
  ) { }

  getForm(step: FormName): AbstractControl {
    switch (step) {
      case 'debtor': return this.getDebtorForm();
      case 'claims': return this.getClaimsForm();
      case 'claim': return this.getClaimForm();
      case 'client': return this.getClientForm();
      case 'user': return this.getUserEmailForm();
      case 'bank-account': return this.getBankAccountForm();
      case 'summary': return this.getSummaryForm();
      case 'szamlazzhu-invoice': return this.getSzamlazzhuInvoiceForm(this.data);
      case 'szamlazzhu-invoices': return this.getSzamlazzhuInvoicesForm();
      default: throw new Error(`Unknown step ${step}`);
    }
  }

  protected readonly getClaimsForm = getClaimsForm.bind(this);
  protected readonly getClaimForm = getClaimForm.bind(this);
  protected readonly getDebtorForm = getDebtorForm.bind(this);
  protected readonly getClientForm = getClientForm.bind(this);
  protected readonly getUserEmailForm = getUserEmailForm.bind(this);
  protected readonly getBankAccountForm = getBankAccountForm.bind(this);
  protected readonly getSummaryForm = getSummaryForm.bind(this);

  // Szamlazz.hu
  protected readonly getSzamlazzhuInvoicesForm = getSzamlazzhuInvoicesForm.bind(this);
  protected readonly getSzamlazzhuInvoiceForm = getSzamlazzhuInvoiceForm.bind(this);
}