import { FormGroup, Validators } from "@angular/forms";
import { AxiosError } from "axios";
import moment, { isMoment } from "moment";
import { debounceTime, distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { errorResponse } from "src/types/error-response";
import { FormFactory } from "../form.factory";
import { Claim } from "../form.resources";
import { maxDateValidator } from "../validators/max-date";
import { numberValidator } from "../validators/number";

export function getClaimForm(this: FormFactory, claim?: Claim): FormGroup {
  const isEditable = claim?.is_editable !== false;
  const isDeletable = claim?.is_deletable !== false;

  const maxDueDate = moment().subtract(1, 'day').endOf('day');
  const form = this.fb.group({
    file: [claim?.file, Validators.required],
    original_amount: [
      claim?.original_amount,
      [
        Validators.required,
        numberValidator,
        Validators.min(1),
        Validators.max(30_000_000),
      ],
    ],
    due_date_at: [
      claim?.due_date_at ? moment(claim.due_date_at) : moment().subtract(1, 'day'),
      [Validators.required, maxDateValidator(maxDueDate)],
    ],

    // data for convenience
    id: claim?.id ?? (this.formService.isGuest ? new Date().valueOf().toString() : null),
    is_deletable: isDeletable,
    is_editable: isEditable,
  });

  if (isEditable === false) {
    Object.values(form.controls).forEach(control => {
      if (control.valid) {
        setTimeout(() => control.disable({ onlySelf: true }));
      }
    });
  }

  const original_amount = form.get('original_amount');
  original_amount.valueChanges
    .pipe(
      debounceTime(0),
      startWith(original_amount.value),
    )
    .subscribe({
      next: value => {
        if (typeof value === 'number') {
          if (value === 0 || Number.isNaN(value)) {
            original_amount.setValue(null);
          }
          return;
        }
        if (!value) {
          return;
        }

        if (typeof value === 'string') {
          original_amount.setValue(+value.replace(/\D/g, ''));
        } else {
          console.warn('Unexpected type');
          original_amount.setValue(null);
        }
      },
    });

  const due_date_at = form.get('due_date_at');
  const file = form.get('file');
  const id = form.get('id');
  file.valueChanges
    .pipe(
      startWith(file.value),
      debounceTime(0),
    )
    .subscribe({
      next: (file: Claim['file'] | File | null) => {
        if (original_amount.enabled === !!file && due_date_at.enabled === !!file) {
          return;
        }

        const controls = [original_amount, due_date_at];
        if (!file) {
          for (const c of controls) {
            c.disable({ onlySelf: true });
          }
        } else {
          for (const c of controls) {
            c.enable({ onlySelf: true });
          }
          if (!isEditable) {
            for (const c of controls) {
              if (c.valid) {
                c.disable({ onlySelf: true });
              }
            }
          }
        }
      }
    });

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(() => form.valid),
      map(() => JSON.stringify(form.value)),
      distinctUntilChanged(),
    )
    .subscribe({
      next: async () => {
        try {
          const value = form.value as Claim;
          const formattedDueDateAt = isMoment(due_date_at.value)
            ? due_date_at.value.format('YYYY-MM-DD')
            : due_date_at.value;

          const params: Claim = {
            id: id.value,
            original_amount: original_amount.value,
            due_date_at: formattedDueDateAt,
            file: file.value instanceof File ? file.value : undefined,
          };

          const upsertedClaim = await this.formService.upsertClaim(params);
          if (!value.id) {
            form.patchValue(
              {
                id: upsertedClaim.id,
              },
              {
                emitEvent: false,
                onlySelf: true,
              }
            );
          }
        } catch (error) {
          console.error('Error while saving claim', error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 413) {
              form.markAsDirty();
              form.get('file').setErrors({
                unknown: 'Túl nagy a fájl',
              });
              return;
            } else if (error.response?.status === 422) {
              const errorData = errorResponse.safeParse(error.response?.data);
              if (errorData.success) {
                Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                  form.get(path).setErrors({
                    unknown: errors[0],
                  });
                });
              }
            }
          }
        }
      }
    });

  return form;
}