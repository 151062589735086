import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { FormTypeEnum } from 'src/app/aa-new-form/form-steps/init-step/models/common/form-type-enum';
import { TransformFormDataService } from 'src/app/aa-new-form/form-steps/init-step/services/transform-form-data.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { formComponents } from './form.components';
import { Form, FormFactory, FormName } from './form.factory';
import { BankAccount, bankAccountSchema, Campaign, campaignSchema, Claim, claimsSchema, Client, clientSchema, Debtor, debtorSchema, ProductType, SzamlazzhuInvoice, UserEmail, userEmailSchema } from './form.resources';

export type Case = {
  id: string;
  payee_case_reference_id: string;
  type: FormTypeEnum;
  partner_id: string | null;
};

export type FormStepName = 'debtor' | 'client' | 'payment' | 'payment-redirect';
export const formStepNames: FormStepName[] = [
  'debtor',
  'client',
  'payment',
  'payment-redirect',
];
export const nextFormStep: Record<FormStepName, FormStepName | null> = {
  "payment-redirect": null,
  client: 'payment',
  debtor: 'client',
  payment: null,
};

export type FormStepResponse = {
  forms: {
    name: FormName,
    data: Form[FormName],
  }[];
  payee_case: Case;
};

export type SelectOptionalClaimsParams = {
  is_flat_rate_cost_claim_added: boolean;
  is_interest_added: boolean;
};

export type SyncSzamlazzhuInvoicesParams = {
  invoices: {
    invoice_id: string;
    file: File | null;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private db?: IDBDatabase;

  forms: {
    name: FormName,
    data: Form[FormName],
  }[] = [];
  case: Case;

  campaign: Campaign = {};

  readonly form: FormGroup = new FormGroup({});
  caseId: 'guest' | string & {};
  get isGuest(): boolean { return this.caseId === 'guest'; }

  readonly saving = new BehaviorSubject<number>(0);
  readonly justSaved = new BehaviorSubject<number>(0);
  readonly paymentLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: FormBuilder,
    private transformFormDataService: TransformFormDataService,
    private router: Router,
    private authService: AuthService,
  ) { }

  setCaseId(caseId: string): FormService {
    this.caseId = caseId;
    return this;
  }

  async setFormData(step: FormStepName): Promise<void> {
    if (this.caseId === 'guest') {
      await this.loadFormDataFromLocalSave(step);
      return;
    }

    const url = `${environment.baseUrl}/api/case-form/${this.caseId}/step/${step}`;
    const result = await axios.get<FormStepResponse>(url);
    this.forms = result.data.forms;
    this.case = result.data.payee_case;
  }

  getFormComponents(): { name: FormName, component: ComponentType<unknown>; }[] {
    const components: { name: FormName, component: ComponentType<unknown>; }[] = [];
    for (const { name } of this.forms) {
      components.push({
        component: formComponents[name],
        name: name,
      });
    }
    return components;
  }

  setFormGroup(): FormService {
    Object.keys(this.form.controls).forEach(key => this.form.removeControl(key, { emitEvent: false }));

    for (const { data, name } of this.forms) {
      const form = new FormFactory(this, this.fb, data).getForm(name);

      this.form.addControl(name, form, { emitEvent: false });
    };

    return this;
  }

  async upsertDebtor(params: Debtor): Promise<void> {
    await this.save(async () => {
      if (this.isGuest) {
        this.saveFormLocally('debtor', params);
        return;
      }

      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/debtor`;
      await axios.post(url, params, { timeout: 5000 });
    });
  }

  async upsertClaim(params: Claim): Promise<Claim> {
    return await this.save(async () => {
      if (this.isGuest) {
        this.saveFormLocally('claims', params);
        return params;
      }

      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/claim`;
      const formData = this.transformFormDataService.objectToFormData(params);
      const result = await axios.post<{ claim: Claim; }>(url, formData, { timeout: 5000 });
      return result.data.claim;
    });
  }

  async deleteClaim(id: string): Promise<void> {
    await this.save(async () => {
      if (this.isGuest) {
        this.deleteFromLocalStorage('claims', id);
        return;
      }

      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/claim/${id}`;
      await axios.delete(url, { timeout: 5000 });
    });
  }

  async updateClient(params: Client): Promise<void> {
    await this.save(async () => {
      if (this.isGuest) {
        this.saveFormLocally('client', params);
        return;
      }

      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/client`;
      await axios.post(url, params, { timeout: 5000 });
    });
  }

  async upsertBankAccount(params: BankAccount): Promise<void> {
    await this.save(async () => {
      if (this.isGuest) {
        this.saveFormLocally('bank-account', params);
        return;
      }

      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/bank-account`;
      await axios.post(url, params, { timeout: 5000 });
    });
  }

  async selectOptionalClaims(params: SelectOptionalClaimsParams): Promise<void> {
    if (this.isGuest) {
      return;
    }

    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/select-optional-claims`;
      const result = await axios.post<FormStepResponse>(url, params);

      this.forms = result.data.forms;
      this.setFormGroup();
    });
  }

  async changeProduct(product_type: ProductType): Promise<void> {
    if (this.isGuest) {
      return;
    }

    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/change-product/${product_type}`;
      const result = await axios.post<FormStepResponse>(url);

      this.forms = result.data.forms;
      this.setFormGroup();
    });
  }

  async upsertDebtorContacts(email: string): Promise<void> {
    if (this.isGuest) {
      return;
    }

    if (this)
      await this.save(async () => {
        const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/debtor-contacts`;
        await axios.post(url, {
          email,
        });
      });
  }

  async syncSzamlazzhuInvoices(params: SyncSzamlazzhuInvoicesParams): Promise<SzamlazzhuInvoice[]> {
    return await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/szamlazzhu/invoice`;
      const formData = this.transformFormDataService.objectToFormData(params);
      const result = await axios.post<{ invoices: SzamlazzhuInvoice[]; }>(url, formData);
      return result.data.invoices;
    });
  }

  async updateGuestUserEmail(params: UserEmail): Promise<void> {
    if (!this.isGuest) {
      return;
    }

    await this.save(async () => {
      this.saveFormLocally('user', params);
    });
  }

  async saveCampaignLocally(): Promise<void> {
    await this.saveFormLocally('campaign', {
      id: 'campaign',
      ...this.campaign,
    });
  }

  async registerGuest(): Promise<void> {
    if (this.form.invalid) {
      console.warn('Invalid form');
      return;
    }

    const client = this.form.get('client').value as Client;
    const userEmail = this.form.get('user').value as UserEmail;
    const bank_account = this.form.get('bank-account').value as BankAccount;

    const rawSavedDebtor = await this.getSavedFormFromLocalSave('debtor');
    const debtor = debtorSchema.safeParse(rawSavedDebtor, { path: ['debtor'] });
    if (!debtor.success) {
      await this.router.navigateByUrl(`case/guest/debtor`);
      return;
    }

    const rawSavedClaims = await this.getSavedFormFromLocalSave('claims');
    const claims = claimsSchema.safeParse(rawSavedClaims, { path: ['claims'] });
    if (!claims.success) {
      await this.router.navigateByUrl(`case/guest/debtor`);
      return;
    }

    const url = `${environment.baseUrl}/api/case-form/register-guest`;
    const params = this.transformFormDataService.objectToFormData({
      user: userEmail,
      client,
      claims: claims.data,
      debtor: debtor.data,
      bank_account,
      campaign: this.campaign,
    });
    params.forEach((_, key) => {
      if (key.includes('[id]')) {
        params.delete(key);
      }
    });
    const result = await axios.post<{
      payee_case: Case;
      access_token: string;
    }>(url, params);

    this.case = result.data.payee_case;
    this.forms = [];
    this.authService.setTokens({
      access_token: result.data.access_token,
      expires_in: null,
      id_token: null,
      refresh_token: null,
    });

    this.router.navigateByUrl(`case/${this.case.id}/payment`);
  }

  async startWithCard(): Promise<void> {
    if (this.paymentLoading.value) {
      return;
    }

    await this.save(async () => {
      try {
        this.paymentLoading.next(true);
        const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/start-with-card`;
        const result = await axios.post<{ payment_url: string; }>(url);
        window.location.href = result.data.payment_url;
      } finally {
        this.paymentLoading.next(false);
      }
    });
  }

  async startWithTransfer(): Promise<void> {
    if (this.paymentLoading.value) {
      return;
    }

    await this.save(async () => {
      try {
        this.paymentLoading.next(true);
        const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/start-with-transfer`;
        await axios.post(url);
      } finally {
        this.paymentLoading.next(false);
      }
    });
  }

  private async save<T>(callback: () => Promise<T>): Promise<T> {
    try {
      this.saving.next(this.saving.value + 1);

      const result = await callback();

      this.justSaved.next(this.justSaved.value + 1);
      setTimeout(() => this.justSaved.next(this.justSaved.value - 1), 2500);

      return result;
    } finally {
      setTimeout(() => this.saving.next(this.saving.value - 1), 200);
    }
  }

  private openDatabaseConnection(): Promise<void> {
    if (this.db) {
      return;
    }

    return new Promise(res => {
      const request = indexedDB.open('case-form-db', 3);

      // Database is created
      request.onupgradeneeded = event => {
        this.db = request.result;

        if (event.oldVersion < 1) {
          this.db.createObjectStore("debtor", { keyPath: 'id' });
          this.db.createObjectStore("claims", { keyPath: 'id' });
          this.db.createObjectStore("client", { keyPath: 'id' });
          this.db.createObjectStore("bank-account", { keyPath: 'id' });
        }

        if (event.oldVersion < 2) {
          this.db.createObjectStore("user", { keyPath: 'id' });
        }

        if (event.oldVersion < 3) {
          this.db.createObjectStore("campaign", { keyPath: 'id' });
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        res();
      };

      request.onerror = (event: any) => {
        console.error('Error while opening database', {
          error: event.target.error,
        });
        res();
      };
    });
  }

  private async loadFormDataFromLocalSave(step: FormStepName): Promise<void> {
    this.forms.length = 0;

    this.case = {
      id: 'guest',
      partner_id: null,
      payee_case_reference_id: null,
      type: FormTypeEnum.HARD,
    };

    const rawSavedCampaign = await this.getSavedFormFromLocalSave('campaign');
    const campaign = campaignSchema.safeParse(rawSavedCampaign);
    if (campaign.success) {
      this.campaign = campaign.data;
    }

    if (step === 'debtor') {
      const rawSavedDebtor = await this.getSavedFormFromLocalSave('debtor');
      const savedDebtor = debtorSchema.safeParse(rawSavedDebtor);
      const debtor: Debtor = savedDebtor.success
        ? savedDebtor.data
        : {
          address: {
            country_iso: 'HU',
            postcode: '',
            settlement: '',
            street: '',
          },
          debtor_type: 'org',
          name: '',
          party_type_id: null,
          representative: null,
          tax_number: '',
        };
      if (!savedDebtor.success && rawSavedDebtor) {
        this.deleteFromLocalStorage('debtor');
        console.warn('Error while loading debtor from local storage', {
          errors: savedDebtor.error,
        });
      }

      const rawSavedClaims = await this.getSavedFormFromLocalSave('claims');
      const savedClaims = claimsSchema.safeParse(rawSavedClaims);
      const claims = savedClaims.success
        ? savedClaims.data
        : [];
      if (!savedClaims.success && rawSavedClaims) {
        this.deleteFromLocalStorage('claims');
        console.warn('Error while loading claims from local storage', {
          errors: savedClaims.error,
        });
      }

      this.forms.push({
        data: debtor,
        name: 'debtor',
      });
      this.forms.push({
        data: claims,
        name: 'claims',
      });
    } else if (step === 'client') {
      const rawSavedClient = await this.getSavedFormFromLocalSave('client');
      const savedClient = clientSchema.safeParse(rawSavedClient);
      const client: Client = savedClient.success
        ? savedClient.data
        : {
          address: {
            country_iso: 'HU',
            postcode: '',
            settlement: '',
            street: '',
          },
          birth_date: null,
          birth_place: '',
          client_type: 'org',
          mother_name: '',
          name: '',
          party_type_id: null,
          representative: null,
          tax_number: '',
        };
      if (!savedClient.success && rawSavedClient) {
        this.deleteFromLocalStorage('client');
        console.warn('Error while loading client from local storage', {
          errors: savedClient.error,
          data: rawSavedClient,
        });
      }

      const rawSavedBankAccount = await this.getSavedFormFromLocalSave('bank-account');
      const savedBankAccount = bankAccountSchema.safeParse(rawSavedBankAccount);
      const bankAccount: BankAccount = savedBankAccount.success
        ? savedBankAccount.data
        : {
          account_holder_name: '',
          account_number: '',
          // bic: '',
          iban: '',
        };
      if (!savedBankAccount.success && rawSavedBankAccount) {
        this.deleteFromLocalStorage('bank-account');
        console.warn('Error while loading client bank account from local storage', {
          errors: savedBankAccount.error,
          data: rawSavedBankAccount,
        });
      }

      const rawSavedUserEmail = await this.getSavedFormFromLocalSave('user');
      const savedUserEmail = userEmailSchema.safeParse(rawSavedUserEmail);
      const userEmail: UserEmail = savedUserEmail.success
        ? savedUserEmail.data
        : {
          email: '',
        };
      if (!savedUserEmail.success && rawSavedUserEmail) {
        this.deleteFromLocalStorage('user');
        console.warn('Error while loading user from local storage', {
          errors: savedUserEmail.error,
          data: rawSavedUserEmail,
        });
      }

      this.forms.push({
        data: client,
        name: 'client',
      });
      this.forms.push({
        data: userEmail,
        name: 'user',
      });
      this.forms.push({
        data: bankAccount,
        name: 'bank-account',
      });
    } else {
      console.warn('Step not allowed for guest');
      this.router.navigateByUrl(`/case/guest/debtor`);
    }
  }

  private async getSavedFormFromLocalSave<T extends FormName>(step: T): Promise<
    T extends 'claims' ? unknown[] :
    unknown | null
  > {
    await this.openDatabaseConnection();
    if (!this.db) {
      return null;
    }

    const transaction = this.db.transaction(step, 'readonly');
    const store = transaction.objectStore(step);

    return new Promise(res => {
      const request = store.getAll();
      request.onerror = (event: any) => {
        console.warn('Error while getting form data', {
          step,
          error: event.target.error,
        });

        res(null);
      };
      request.onsuccess = () => {
        if (step === 'claims') {
          res(request.result as any);
        } else {
          res(request.result?.[0] ?? null);
        }
      };
    });
  }

  private async saveFormLocally<T extends { id?: string; }>(step: FormName, data: T): Promise<void> {
    await this.openDatabaseConnection();
    if (!this.db) {
      return null;
    }

    const transaction = this.db.transaction(step, 'readwrite');
    const store = transaction.objectStore(step);
    store.put(data);
  }

  private async deleteFromLocalStorage(step: FormName, id?: string): Promise<void> {
    await this.openDatabaseConnection();
    if (!this.db) {
      return null;
    }

    const transaction = this.db.transaction(step, 'readwrite');
    const store = transaction.objectStore(step);

    if (id) {
      store.delete(id);
    } else {
      store.clear();
    }
  }
}
