import { ClaimsFormComponent } from "src/app/components/case-form/forms/claims-form/claims-form.component";
import { FormName } from "./form.factory";
import { DebtorFormComponent } from "src/app/components/case-form/forms/debtor-form/debtor-form.component";
import { ClientFormComponent } from "src/app/components/case-form/forms/client-form/client-form.component";
import { BankAccountFormComponent } from "src/app/components/case-form/forms/bank-account-form/bank-account-form.component";
import { SummaryFormComponent } from "src/app/components/case-form/forms/summary-form/summary-form.component";
import { UserEmailFormComponent } from "src/app/components/case-form/forms/user-email-form/user-email-form.component";
import { ComponentType } from "@angular/cdk/portal";
import { SzamlazzhuInvoicesFormComponent } from "src/app/components/case-form/forms/szamlazzhu-invoices-form/szamlazzhu-invoices-form.component";

export const formComponents: Record<FormName, ComponentType<unknown>> = {
  debtor: DebtorFormComponent,
  claims: ClaimsFormComponent,
  claim: null,
  client: ClientFormComponent,
  "bank-account": BankAccountFormComponent,
  "user": UserEmailFormComponent,
  summary: SummaryFormComponent,

  // Szamlazz.hu
  "szamlazzhu-invoice": null,
  "szamlazzhu-invoices": SzamlazzhuInvoicesFormComponent,

  // Helper
  campaign: null,
};
