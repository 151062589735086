import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { debounceTime, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { FormFactory } from 'src/app/services/form/form.factory';
import { Claim } from 'src/app/services/form/form.resources';
import { FormService } from 'src/app/services/form/form.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';

@Component({
  selector: 'app-claims-form',
  templateUrl: './claims-form.component.html',
  styleUrls: ['./claims-form.component.scss']
})
export class ClaimsFormComponent implements OnInit, OnDestroy {
  @Input() caseId: string;
  get form(): FormArray { return this.formService.form.get('claims') as FormArray; }

  filteredClaims: AbstractControl[] = [];
  pageIndex = 0;
  pageSize = 5;

  get isEditable(): boolean { return this.form.controls.some(c => c.value.is_editable); }

  private readonly destroy = new Subject<void>();

  constructor(
    private formService: FormService,
    private fb: FormBuilder,
    private helpersService: HelpersService,
  ) { }

  ngOnInit(): void {
    this.changePage({
      length: this.form.length,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy),
        startWith(this.form.value),
        pairwise(),
      )
      .subscribe({
        next: ([prev, claims]: [Claim[], Claim[]]) => {
          // If claim was added we have to navigate to its page
          const maxPageIndex = Math.max(1, Math.ceil(claims.length / this.pageSize)) - 1;
          if (prev.length < claims.length) {
            this.changePage({
              length: this.form.length,
              pageIndex: maxPageIndex,
              pageSize: this.pageSize,
            });
          } else if (prev.length > claims.length) {
            this.changePage({
              length: this.form.length,
              pageIndex: this.pageIndex > maxPageIndex
                ? maxPageIndex
                : this.pageIndex,
              pageSize: this.pageSize,
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  addClaim(): void {
    if (this.form.valid) {
      const form = new FormFactory(this.formService, this.fb).getForm('claim');

      this.form.push(form);
    } else {
      this.helpersService.markAllChildrenAsDirty(this.form);
    }
  }

  changePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.filteredClaims = this.form.controls
      .slice(event.pageIndex * event.pageSize, (event.pageIndex + 1) * event.pageSize);
  }
}
