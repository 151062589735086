import { FormArray, FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { SzamlazzhuInvoice } from "../form.resources";
import { totalAmountValidator } from "../validators/total-amount";
import { debounceTime, filter } from "rxjs/operators";
import { SyncSzamlazzhuInvoicesParams } from "../form.service";

export function getSzamlazzhuInvoicesForm(this: FormFactory): FormArray {
  const data = this.data as SzamlazzhuInvoice[];

  const form = this.fb.array(
    data.map(invoice => this.getSzamlazzhuInvoiceForm(invoice)),
    [Validators.required, totalAmountValidator('total_remaining_amount')],
  );

  this.formService.saving.subscribe({
    next: s => {
      if (s > 0) {
        form.disable({ onlySelf: true, emitEvent: false });
      } else {
        form.enable({ onlySelf: true, emitEvent: false });
      }
    }
  });

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(s => s === 'VALID'),
    )
    .subscribe({
      next: async () => {
        try {
          const szamlazzhuInvoices: Partial<SzamlazzhuInvoice>[] = form.value;
          const params: SyncSzamlazzhuInvoicesParams = {
            invoices: szamlazzhuInvoices.map(invoice => ({
              invoice_id: invoice.invoice_id,
              file: invoice.file instanceof File ? invoice.file : null,
            })),
          };
          const invoices = await this.formService.syncSzamlazzhuInvoices(params);
          for (const invoice of invoices) {
            const formGroup = form.controls.find(control => control.value.invoice_id === invoice.invoice_id) as FormGroup;
            if (formGroup) {
              formGroup.patchValue(invoice, { emitEvent: false });
            }
          }
        } catch (error) {
          console.error('Error while syncing invoices', error);
        }
      }
    });

  return form;
}