<app-form-header></app-form-header>
<main class="flex column">
  <payee-autosave [class.show]="justSaved" class="saved-text"> </payee-autosave>
  <ng-container *ngIf="loading && canShowSpinner">
    <mat-progress-spinner
      style="align-self: center"
      diameter="100"
      mode="indeterminate"
    ></mat-progress-spinner>
  </ng-container>
  <ng-container *ngFor="let component of formComponents">
    <ng-container
      *ngIf="!!form.get(component.name)"
      [ngComponentOutlet]="component.component"
    ></ng-container>
  </ng-container>
  <button
    *ngIf="showNextPageButton"
    payee-button
    width="short"
    class="next-page-button"
    (click)="nextPage()"
  >
    <div class="flex hcenter vcenter">
      <span>Folytatás</span>
      <payee-icon name="arrow-right-white" height="10px"></payee-icon>
    </div>
  </button>
</main>
<footer style="margin-top: 50px">
  <app-footer></app-footer>
</footer>
