import { FormArray, Validators } from "@angular/forms";
import { Claim } from "../form.resources";
import { FormFactory } from "../form.factory";
import { totalAmountValidator } from "../validators/total-amount";
import { filter, startWith } from "rxjs/operators";

export function getClaimsForm(this: FormFactory): FormArray {
  const data = this.data as Claim[];

  const form = this.fb.array(
    data.map(claim => this.getClaimForm(claim)),
    [Validators.required, totalAmountValidator('original_amount')],
  );

  form.valueChanges
    .pipe(
      startWith(form.value),
      filter(claims => claims.length === 0),
    )
    .subscribe({
      next: () => form.push(this.getClaimForm())
    });

  return form;
}