import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { Debtor } from "../form.resources";
import { debounceTime, filter, startWith } from "rxjs/operators";
import { errorResponse } from "src/types/error-response";
import { AxiosError } from "axios";

export function getDebtorForm(this: FormFactory): FormGroup {
  const data = this.data as Debtor;
  const isEditable = data?.is_editable !== false;
  const rep = data.representative;

  const form = this.fb.group({
    id: data?.id ?? (this.formService.isGuest ? new Date().valueOf().toString() : null),
    debtor_type: [data.debtor_type, [Validators.required, Validators.pattern(/ind|org/)]],
    name: [data.name, [Validators.required, Validators.maxLength(255)]],
    party_type_id: [data.party_type_id, Validators.required],
    // @todo required if org and not house
    tax_number: data.tax_number,
    address: this.fb.group({
      country_iso: [data.address.country_iso || 'HU', Validators.required],
      postcode: [data.address.postcode, [Validators.required, Validators.maxLength(255)]],
      settlement: [data.address.settlement, [Validators.required, Validators.maxLength(255)]],
      street: [data.address.street, [Validators.required, Validators.maxLength(255)]],
    }),
    // @todo possibly array
    representative: this.fb.group({
      name: [rep?.name, [Validators.required, Validators.maxLength(255)]],
      address: this.fb.group({
        country_iso: [rep?.address?.country_iso || 'HU', [Validators.required]],
        postcode: [rep?.address?.postcode, [Validators.required, Validators.maxLength(255)]],
        settlement: [rep?.address?.settlement, [Validators.required, Validators.maxLength(255)]],
        street: [rep?.address?.street, [Validators.required, Validators.maxLength(255)]],
      }),
    }),
    is_editable: isEditable,
  });

  if (isEditable === false && form.valid) {
    disableValidControls(form);
  }

  const representative = form.get('representative');
  const party_type_id = form.get('party_type_id');
  const tax_number = form.get('tax_number');

  form.valueChanges
    .pipe(startWith(data))
    .subscribe({
      next: (v: Debtor) => {
        if (v.debtor_type === 'ind') {
          representative.disable({ onlySelf: true, emitEvent: false });
          party_type_id.disable({ onlySelf: true, emitEvent: false });
          tax_number.disable({ onlySelf: true, emitEvent: false });
        } else {
          representative.enable({ onlySelf: true, emitEvent: false });
          party_type_id.enable({ onlySelf: true, emitEvent: false });
          tax_number.enable({ onlySelf: true, emitEvent: false });
        }
        form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      },
    });

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(() => form.valid),
    )
    .subscribe({
      next: async () => {
        try {
          await this.formService.upsertDebtor(form.value);
        } catch (error) {
          console.error('Error while saving debtor', error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
              const errorData = errorResponse.safeParse(error.response?.data);
              if (errorData.success) {
                Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                  form.get(path).setErrors({
                    unknown: errors[0],
                  });
                });
              }
            }
          }
        }
      }
    });

  return form;
}

function disableValidControls(form: AbstractControl): void {
  if (form.valid) {
    form.disable({ emitEvent: false, onlySelf: true });
  }
  if (form instanceof FormGroup) {
    for (const control of Object.values(form.controls)) {
      disableValidControls(control);
    }
  }
}
