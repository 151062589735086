<h2>Mik a követelések?</h2>
<section class="forms flex column">
  <span *ngIf="form.dirty && form.invalid" color="alert-red">
    <ng-container *ngIf="form.hasError('max')">
      <!-- TODO: currency -->
      A követelések összege nem haladhatja meg a
      {{ form.errors.max.max | number : "1.0" : "hu" }}
      forintot
    </ng-container>
  </span>
  <app-claim-form
    *ngFor="let claimForm of filteredClaims | keyvalue"
    [form]="claimForm.value"
    [index]="+claimForm.key + pageIndex * pageSize"
  ></app-claim-form>
  <span *ngIf="form.dirty && form.invalid" color="alert-red">
    <ng-container *ngIf="form.hasError('max')">
      <!-- TODO: currency -->
      A követelések összege nem haladhatja meg a
      {{ form.errors.max.max | number : "1.0" : "hu" }}
      forintot
    </ng-container>
  </span>
  <mat-paginator
    *ngIf="form.length > 5"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [length]="form.length"
    (page)="changePage($event)"
    [pageSizeOptions]="[1, 5, 10]"
  ></mat-paginator>
</section>
<button
  *ngIf="isEditable"
  payee-button
  color="white"
  class="top-margin-15 new-claim-button"
  (click)="addClaim()"
>
  <div class="flex vcenter">
    <payee-icon name="plus-blue"></payee-icon>
    <span color="blue-king">Követelés hozzáadása</span>
  </div>
</button>
