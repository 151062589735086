import { FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { SzamlazzhuInvoice } from "../form.resources";

export function getSzamlazzhuInvoiceForm(this: FormFactory, invoice: SzamlazzhuInvoice): FormGroup {
  const form = this.fb.group({
    invoice_id: invoice.invoice_id,
    invoice_number: invoice.invoice_number,
    total_remaining_amount: invoice.total_remaining_amount,
    file: [invoice.file, Validators.required],
  });

  return form;
}