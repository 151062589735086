import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeToggleModule } from 'src/app/shared/payee-toggle/payee-toggle.module';
import { SummaryFormComponent } from './summary-form.component';
import { StartWithTransferDialogComponent } from './start-with-transfer-dialog/start-with-transfer-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { MatChipsModule } from '@angular/material/chips';



@NgModule({
  declarations: [
    SummaryFormComponent,
    StartWithTransferDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatTooltipModule,
    PayeeButtonsModule,
    PayeeIconModule,
    PayeeToggleModule,
    ReactiveFormsModule,
    MatDialogModule,
    PayeeFormFieldsModule,
    MatTooltipModule,
  ]
})
export class SummaryFormModule { }
