import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import axios from 'axios';
import { Subject } from 'rxjs';
import { FormFactory } from 'src/app/services/form/form.factory';
import { SzamlazzhuInvoice } from 'src/app/services/form/form.resources';
import { FormService } from 'src/app/services/form/form.service';
import { GetKsziInfoResponse, SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-szamlazzhu-invoices-form',
  templateUrl: './szamlazzhu-invoices-form.component.html',
  styleUrls: ['./szamlazzhu-invoices-form.component.scss']
})
export class SzamlazzhuInvoicesFormComponent implements OnInit, OnDestroy {
  private invoicesLoadingCounter = 0;
  get invoicesLoading(): boolean { return this.invoicesLoadingCounter > 0; }

  get form(): FormArray { return this.formService.form.get('szamlazzhu-invoices') as FormArray; }
  /**
   * Stores the indeces of selected invoices Map<invoice_id, index>
   */
  readonly invoiceIndex = new Map<string, number>();
  get hasMultipleInvoices(): boolean { return this.dataSource.data.length > 1; }

  private ksziInfo?: GetKsziInfoResponse;
  private ksziInterval?: NodeJS.Timeout;
  readonly dataSource = new MatTableDataSource<SzamlazzhuInvoice>();
  readonly displayedColumns = [
    'selected',
    'invoice_number',
    'due_date_at',
    'remaining_amount',
    'file',
  ] as const;

  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (this.dataSource.sort === sort) {
      return;
    }
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  private readonly destroy = new Subject<void>();

  constructor(
    private formService: FormService,
    private szamlazzhuService: SzamlazzhuService,
    private fb: FormBuilder,
  ) {
    this.dataSource.sortingDataAccessor = (data, headerId: typeof this.displayedColumns[number]) => {
      switch (headerId) {
        case 'selected': return this.invoiceIndex.has(data.invoice_id) ? 1 : 0;
        case 'file': return !data.file ? 0 : data.file instanceof File ? 1 : 2;
        case 'remaining_amount': return data.total_remaining_amount;
        default: return data[headerId];
      }
    };
  }

  async ngOnInit(): Promise<void> {
    this.setInvoicesFromForm();
    await this.getInvoices();
    this.scheduleKsziInfo();

    this.formService.saving.subscribe({
      next: async (s) => {
        if (s <= 0) {
          await this.getInvoices();
        }
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.ksziInterval);
    this.destroy.next();
    this.destroy.complete();
  }

  async toggleInvoice(invoice: SzamlazzhuInvoice): Promise<void> {
    if (this.invoicesLoading) {
      console.warn("Invoice toggle not allowed - loading")
      return;
    }

    if (!this.invoiceIndex.has(invoice.invoice_id)) {
      if (!invoice.file) {
        try {
          invoice.file = await this.getInvoiceFile(invoice);
        } catch (error) {
          console.error('Error while getting invoice file');
        }
      }

      const form = new FormFactory(this.formService, this.fb, invoice).getForm('szamlazzhu-invoice');
      this.invoiceIndex.set(invoice.invoice_id, this.form.length);
      this.form.push(form);
    } else {
      this.form.removeAt(this.invoiceIndex.get(invoice.invoice_id));
      this.invoiceIndex.delete(invoice.invoice_id);
    }
    this.form.markAsDirty();
  }

  async toggleAllInvoices(): Promise<void> {
    if (this.form.length !== this.dataSource.data.length) {
      for (const invoice of this.dataSource.data) {
        if (!this.invoiceIndex.has(invoice.invoice_id)) {
          await this.toggleInvoice(invoice);
        }
      }
    } else {
      for (const invoice of this.dataSource.data) {
        if (this.invoiceIndex.has(invoice.invoice_id)) {
          await this.toggleInvoice(invoice);
        }
      }
    }
  }

  uploadInvoice(event: Event, invoice: SzamlazzhuInvoice): void {
    if (invoice.file && !(invoice.file instanceof File)) {
      console.warn("Can't replace already uploaded file");
      return;
    }

    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files.length <= 0) {
      return;
    }

    const file = files.item(0);
    invoice.file = file;
    inputElement.value = '';

    if (this.invoiceIndex.has(invoice.invoice_id)) {
      const index = this.invoiceIndex.get(invoice.invoice_id);
      this.form.at(index).patchValue({
        file,
      });
      console.log('patched value in form', {
        index,
        control: this.form.at(index)
      });
    }
  }

  removePendingFile(event: Event, invoice: SzamlazzhuInvoice): void {
    event.stopPropagation();

    invoice.file = null;
    if (this.invoiceIndex.has(invoice.invoice_id)) {
      const index = this.invoiceIndex.get(invoice.invoice_id);
      this.form.at(index).patchValue({
        file: null,
      });
    }
  }

  // Set datasource from the already selected invoices
  private setInvoicesFromForm(): void {
    const formData = this.formService.forms.find(f => f.name === 'szamlazzhu-invoices');
    if (formData) {
      this.dataSource.data = formData.data as SzamlazzhuInvoice[];
      for (let i = 0; i < this.dataSource.data.length; ++i) {
        this.invoiceIndex.set(this.dataSource.data[i].invoice_id, i);
      }
    }
  }

  private async getInvoices(): Promise<void> {
    try {
      ++this.invoicesLoadingCounter;
      const url = `${environment.baseUrl}/api/case-form/${this.formService.caseId}/szamlazzhu/invoice`;
      const result = await axios.get<{ invoices: SzamlazzhuInvoice[]; }>(url);
      /**
       * We assign the invoices in this order to prevent the rows from jumping around.
       * 
       * 1. Add invoices that were already selected
       * 2. Get all available invoices
       *    - If the invoice is new add it to the end of the list
       *    - If the invoice is already added update its contents
       */
      const invoices = this.dataSource.data;
      for (const invoice of result.data.invoices) {
        const index = invoices.findIndex(i => i.invoice_id === invoice.invoice_id);
        if (index === -1) {
          invoices.push(invoice);
        } else {
          invoices[index] = invoice;
        }
      }
      this.dataSource.data = invoices;
    } catch (error) {
      console.error('Error while getting szamlazzhu invoices', error);
    } finally {
      --this.invoicesLoadingCounter;
    }
  }

  private async getInvoiceFile(invoice: SzamlazzhuInvoice): Promise<SzamlazzhuInvoice['file']> {
    try {
      ++this.invoicesLoadingCounter;
      const url = `${environment.baseUrl}/api/case-form/${this.formService.caseId}/szamlazzhu/invoice-file/${invoice.invoice_id}`;
      const result = await axios.get<{ file: SzamlazzhuInvoice['file']; }>(url);
      return result.data.file;
    } catch (error) {
      console.error('Error while getting szamlazzhu invoice file', error);
    } finally {
      --this.invoicesLoadingCounter;
    }
  }

  private scheduleKsziInfo(): void {
    this.ksziInterval = setInterval(
      async () => {
        try {
          const oldLoading = this.ksziInfo?.invoices_pending ?? 0;
          const oldInvoicesRecieved = this.ksziInfo?.invoices_recieved ?? 0;
          this.ksziInfo = await this.szamlazzhuService.getKsziInfo();
          if (
            this.ksziInfo?.invoices_pending < oldLoading
            || this.ksziInfo?.invoices_recieved < oldInvoicesRecieved
          ) {
            await this.getInvoices();
          }
        } catch (error) {
          console.error('Error while getting kszi info', error);
        }
      },
      // Poll every minute
      60_000,
    );
  }
}
