import { FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { ProductType, Summary } from "../form.resources";
import { debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { merge } from "rxjs";
import { AxiosError } from "axios";
import { errorResponse } from "src/types/error-response";

export function getSummaryForm(this: FormFactory): FormGroup {
  const data = this.data as Summary;

  const form = this.fb.group({
    product_type: data.product.type,
    debtor: this.fb.group({
      email: data.debtor.email,
    }),
    payee_case: this.fb.group({
      is_start_case_price_claim_added: { value: data.payee_case.is_start_case_price_claim_added, disabled: true },
      is_interest_added: data.payee_case.is_interest_added,
      is_flat_rate_cost_claim_added: data.payee_case.is_flat_rate_cost_claim_added,
    }),
  });

  if (!data.product.is_product_type_editable) {
    form.get('product_type').disable({ onlySelf: true });
  }

  const debtorEmail = form.get('debtor.email');

  const defaultForms = data.product.forms[data.product.type];
  debtorEmail.setValidators(defaultForms.includes('debtor_form')
    ? [Validators.required, Validators.email]
    : []);
  debtorEmail.updateValueAndValidity();
  form.get('payee_case.is_flat_rate_cost_claim_added').setValidators(defaultForms.includes('flat_rate_cost_added_form')
    ? [Validators.required]
    : []);
  form.get('payee_case.is_flat_rate_cost_claim_added').updateValueAndValidity();
  form.get('payee_case.is_interest_added').setValidators(defaultForms.includes('interest_added_form')
    ? [Validators.required]
    : []);
  form.get('payee_case.is_interest_added').updateValueAndValidity();

  this.formService.saving
    .pipe(distinctUntilChanged(), debounceTime(0))
    .subscribe({
      next: saving => {
        if (saving > 0) {
          form.disable({ emitEvent: false });
        } else {
          form.enable({ emitEvent: false });
        }
      },
    });

  merge(
    form.get('payee_case.is_flat_rate_cost_claim_added').valueChanges.pipe(map(v => ({ is_flat_rate_cost_claim_added: v }))),
    form.get('payee_case.is_interest_added').valueChanges.pipe(map(v => ({ is_interest_added: v }))),
  )
    .subscribe({
      next: async (v) => {
        try {
          await this.formService.selectOptionalClaims({
            is_flat_rate_cost_claim_added: form.value.payee_case.is_flat_rate_cost_claim_added,
            is_interest_added: form.value.payee_case.is_interest_added,
            ...v,
          });
        } catch (error) {
          console.error('Error while saving optional claims', error);
        }
      },
    });

  if (data.product.is_product_type_editable) {
    form.get('product_type').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe({
        next: async (product_type: ProductType) => {
          try {
            await this.formService.changeProduct(product_type);
          } catch (error) {
            console.error('Error while changing product', error);
          }
  
          const forms = data.product.forms[product_type];
          debtorEmail.setValidators(forms.includes('debtor_form')
            ? [Validators.required]
            : []);
          debtorEmail.updateValueAndValidity({ emitEvent: false });
          form.get('payee_case.is_flat_rate_cost_claim_added').setValidators(forms.includes('flat_rate_cost_added_form')
            ? [Validators.required]
            : []);
          form.get('payee_case.is_flat_rate_cost_claim_added').updateValueAndValidity({ emitEvent: false });
          form.get('payee_case.is_interest_added').setValidators(forms.includes('interest_added_form')
            ? [Validators.required]
            : []);
          form.get('payee_case.is_interest_added').updateValueAndValidity({ emitEvent: false });
        }
      });
  }

  debtorEmail.valueChanges
    .pipe(
      debounceTime(1000),
      filter(() => debtorEmail.valid),
    )
    .subscribe({
      next: async email => {
        try {
          await this.formService.upsertDebtorContacts(email);
        } catch (error) {
          console.error('Error while upserting debtor contacts', error);
          if (error instanceof AxiosError) {
            const errorData = errorResponse.safeParse(error);
            if (errorData.success) {
              debtorEmail.setErrors({
                unknown: errorData.data.message,
              });
            }
          }
        }
      }
    });

  return form;
}