import { FormControl, ValidatorFn } from "@angular/forms";

export const numberValidator: ValidatorFn = (control: FormControl) => {
  const value = control.value;
  if (!value || typeof value === 'number') {
    return null;
  }

  if (typeof value === 'string' && /^\d+$/.test(value)) {
    return null;
  }

  return {
    notnumber: true,
  };
};