import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AxiosError } from 'axios';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { FormTypeEnum } from 'src/app/aa-new-form/form-steps/init-step/models/common/form-type-enum';
import { CasesService, GetCardPaymentStatus, PaymentStatus } from 'src/app/services/cases/cases.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Form, FormName } from 'src/app/services/form/form.factory';
import { Case, FormService, FormStepName, formStepNames, nextFormStep } from 'src/app/services/form/form.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { errorResponse } from 'src/types/error-response';
import { z } from 'zod';

export const caseNotDraftError = z.object({
  code: z.literal('case-not-draft'),
  payee_case_reference_id: z.string(),
  product_type: z.string(),
});

@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss']
})
export class CaseFormComponent implements OnInit, OnDestroy {
  get form(): FormGroup { return this.formService.form; };
  formComponents: { name: FormName, component: ComponentType<unknown>; }[] = [];
  get case(): Case { return this.formService.case; };
  private canShowSpinnerTimeout?: NodeJS.Timeout;
  canShowSpinner = false;
  loading = true;
  private caseId!: string;
  private step!: FormStepName;
  private readonly destroy = new Subject<void>();

  private readonly paymentInProgressStatuses: PaymentStatus[] = [
    'Started',
    'InProgress',
    'Waiting',
    'Reserved',
    'Authorized',
  ];
  private paymentStatus?: GetCardPaymentStatus;
  private paymentRedirectRetryInterval?: NodeJS.Timeout;
  private retryCount = 10;

  get saving(): boolean { return this.formService.saving.value > 0; }
  get justSaved(): boolean { return this.formService.justSaved.value > 0; }
  get showNextPageButton(): boolean { return !!nextFormStep[this.step]; }

  hasUnsavedChanges = false;

  constructor(
    private casesService: CasesService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private helpersService: HelpersService,
    private authService: AuthService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.formService.campaign.utm_campaign = this.route.snapshot.queryParams.utm_campaign;
    this.formService.campaign.utm_content = this.route.snapshot.queryParams.utm_content;
    this.formService.campaign.utm_medium = this.route.snapshot.queryParams.utm_medium;
    this.formService.campaign.utm_source = this.route.snapshot.queryParams.utm_source;

    this.route.params.subscribe({
      next: async params => {
        this.canShowSpinnerTimeout = setTimeout(() => this.canShowSpinner = true, 200);
        this.loading = true;
        this.caseId = params.uuid;

        if (!this.authService.isAuthenticated) {
          // If not logged in try short token authentication
          const shortToken = this.route.snapshot.queryParams.short_token;
          if (shortToken) {
            try {
              await this.authService.loginWithShortToken(shortToken);
            } catch (error) {
              console.warn('Error while logging in with short token', error);
            }
          }
        }

        if (!this.caseId) {
          if (!this.authService.isAuthenticated) {
            await this.formService.saveCampaignLocally();
            this.router.navigate([`/case/guest`], {
              queryParamsHandling: 'merge',
            });
            return;
          }
          await this.clientsService.getSelectedClient();
          const result = await this.casesService.createCase({
            product_type: 'hard_1',
            utm_campaign: this.formService.campaign.utm_campaign,
            utm_content: this.formService.campaign.utm_content,
            utm_medium: this.formService.campaign.utm_medium,
            utm_source: this.formService.campaign.utm_source,
          });
          this.router.navigate([`/case/${result.id}`], {
            queryParamsHandling: 'merge',
          });
          return;
        }
        this.formService.setCaseId(this.caseId);

        if (this.formService.isGuest) {
          await this.formService.saveCampaignLocally();
        }

        this.step = params.step as FormStepName;
        const paymentId = this.route.snapshot.queryParams.paymentId;
        if (
          !formStepNames.includes(this.step)
          || (this.step === 'payment-redirect' && !paymentId)
        ) {
          this.router.navigate([`/case/${this.caseId}/debtor`], {
            queryParamsHandling: 'merge',
          });
          this.step = 'debtor';
          return;
        }
        this.setTitle();

        await this.setForm();
        if (this.step === 'payment-redirect') {
          await this.checkPayment();
        }

        clearTimeout(this.canShowSpinnerTimeout);
        this.canShowSpinner = false;
        this.loading = false;
      }
    });

    this.formService.saving
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: value => {
          if (value <= 0) {
            this.hasUnsavedChanges = false;
          }
        }
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy),
      )
      .subscribe({
        next: () => {
          this.hasUnsavedChanges = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async nextPage(): Promise<void> {
    if (this.formService.form.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.formService.form);
      return;
    }

    if (this.saving || this.hasUnsavedChanges) {
      return;
    }

    if (this.step === 'client' && this.formService.isGuest) {
      await this.registerGuest();
    } else if (nextFormStep[this.step]) {
      this.router.navigate([`/case/${this.caseId}/${nextFormStep[this.step]}`], {
        queryParamsHandling: 'merge',
      });
    } else {
      console.error('Next step not found', {
        step: this.step,
        nextFormStep,
      });
    }
  }

  private async setForm(): Promise<void> {
    try {
      await this.formService.setFormData(this.step);
      this.formService.setFormGroup();
      this.formComponents = this.formService.getFormComponents();
    } catch (error) {
      console.error('Error while loading case', error);
      if (error instanceof AxiosError) {
        const notDraftError = caseNotDraftError.safeParse(error.response?.data);
        if (notDraftError.success) {
          this.formService.case = {
            payee_case_reference_id: notDraftError.data.payee_case_reference_id,
            type: notDraftError.data.product_type as FormTypeEnum,
            id: '',
            partner_id: '',
          };

          this.router.navigate(['/user/cases'], {
            queryParams: {
              filterType: 'payeeId',
              filter: notDraftError.data.payee_case_reference_id,
            },
          });
          return;
        }

        const notValidError = errorResponse.safeParse(error.response?.data);
        if (notValidError.success) {
          this.router.navigate([`/case/${this.caseId}/debtor`], {
            queryParamsHandling: 'merge',
          });
          return;
        }

        this.router.navigate(['/user/cases']);
        return;
      }
    }
  }

  private setTitle(): void {
    switch (this.step) {
      case 'debtor': return this.titleService.setTitle('Adatmegadás - Az Ön követelései - Payee');
      case 'client': return this.titleService.setTitle('Adatmegadás - Az Ön Cége - Payee');
      case 'payment': return this.titleService.setTitle('Rendelés befejezése - Payee');
      case 'payment-redirect': return this.titleService.setTitle('Rendelés befejezése - Payee');
    }
  }

  private async checkPayment(): Promise<void> {
    return new Promise((res, rej) => {
      this.paymentRedirectRetryInterval = setInterval(async () => {
        try {
          const paymentId = this.route.snapshot.queryParams.paymentId;
          this.paymentStatus = await this.casesService.getCardPaymentStatus(paymentId, this.caseId);
        } catch (error) {
          console.error('Error while getting payment status', error);
          await this.router.navigate([`/case/${this.caseId}/payment`], {
            queryParamsHandling: 'merge',
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        }

        if (this.paymentStatus?.status === 'Succeeded') {
          this.casesService.caseOpenedGtmEvent(
            this.case.type,
            this.case.payee_case_reference_id,
          );
          await this.router.navigate(['/user/cases'], {
            queryParams: {
              filter: this.case.payee_case_reference_id,
              filterType: 'payeeId',
            },
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        } else if (!this.paymentInProgressStatuses.includes(this.paymentStatus.status)) {
          await this.router.navigate([`/case/${this.caseId}/payment`], {
            queryParamsHandling: 'merge',
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        }

        --this.retryCount;
        if (this.retryCount <= 0) {
          clearInterval(this.paymentRedirectRetryInterval);
          await this.router.navigate([`/case/${this.caseId}/payment`], {
            queryParamsHandling: 'merge',
          });
          res();
          return;
        }
      }, 1000);
    });
  }

  private async registerGuest(): Promise<void> {
    try {
      this.loading = true;
      this.canShowSpinnerTimeout = setTimeout(() => this.canShowSpinner = true, 250);

      await this.formService.registerGuest();
    } catch (error) {
      console.error('Error while registering guest', error);
      if (error instanceof AxiosError) {
        const validationError = errorResponse.safeParse(error.response?.data);
        if (validationError.success) {
          this.helpersService.markAllChildrenAsDirty(this.form);
          for (const [path, errors] of Object.entries(validationError.data.errors)) {
            const control = this.form.get(path);
            const error = {
              unknown: errors[0],
            };
            control?.setErrors(error, { emitEvent: false });
          }
        }
      }
    } finally {
      clearTimeout(this.canShowSpinnerTimeout);
      this.canShowSpinner = false;
      this.loading = false;
    }
  }
}
